<template>
	<div id="myTable">
		<el-row>
			<el-col :span="24">
				<el-table
					ref="multipleTable"
					:data="tableData"
					v-loading="loading"
					highlight-current-row
					element-loading-text="檔案加载中..."
					element-loading-spinner="el-icon-loading"
					:header-cell-style="tableHeaderColor"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					@sort-change="sortChange"
					:default-sort="defaultsort"
					:row-style="{height: '50px'}"
					@selection-change="handleSelectChange"
					:row-key="getRowKeys"
					@row-click="rowClick"
					height="600"
				>
					<!-- 这是选择列，你可以根据需要自行调整 -->
					<el-table-column
						width="100"
						type="selection"
						align="center"
						:selectable="checkSelectable"
						disabled="true"
						:reserve-selection="true"
						v-if="checkBoxShow"
					>
					</el-table-column>
					<!-- sortable 預設排序 ,sortable="custom" 後臺排序 -->
					<el-table-column
						v-for="(item, index) in columns"
						:key="index"
						:prop="item.prop"
						:label="item.label"
						:align="item.align"
						:width="item.width || item.minWidth"
						:sortable="item.sortable"
						:show-overflow-tooltip="item.showOverflowTooltip"
					>
						<template slot-scope="scope">
							<Expand
								:scope="scope"
								v-if="item.render == 'action'"
								@editClick="editClick"
								:inDetailedButton="inDetailedButton"
								:inEditButton="inEditButton"
								:inDeleteButton="inDeleteButton"
								:inAddButton="inAddButton"
								@deleteClick="deleteClick"
								@detailedClick="detailedClick"
								@addClick="addClick"
							></Expand>
							<span v-else>
								{{ scope.row[item.prop] }}
							</span>
						</template>
					</el-table-column>
					<!-- 添加用于显示图片的列 -->
					<el-table-column prop="photo" label="照片1" width="100" align="center">
						<template slot-scope="scope">
							<!-- 点击图片触发显示图片弹窗的方法 -->
							<img
								@click="showImage(scope.row.photoBase64_1)"
								:src="'data:image/jpg;base64,' + scope.row.photoBase64_1"
								style="width: 50px; height: 50px"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="photo" label="照片2" width="100" align="center">
						<template slot-scope="scope">
							<!-- 点击图片触发显示图片弹窗的方法 -->
							<img
								@click="showImage(scope.row.photoBase64_2)"
								:src="'data:image/jpg;base64,' + scope.row.photoBase64_2"
								style="width: 50px; height: 50px"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="photo" label="照片3" width="100" align="center">
						<template slot-scope="scope">
							<!-- 点击图片触发显示图片弹窗的方法 -->
							<img
								@click="showImage(scope.row.photoBase64_3)"
								:src="'data:image/jpg;base64,' + scope.row.photoBase64_3"
								style="width: 50px; height: 50px"
							/>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<el-pagination
					style="float: right; margin-top: 5px"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					prev-text="上一頁"
					next-text="下一頁"
					:total="total"
				>
				</el-pagination>
			</el-col>
		</el-row>
		<div v-if="currentImage" class="image-overlay">
			<button @click="closeImage" class="el-dialog__headerbtn">
				<i class="el-dialog__close el-icon el-icon-close"></i>
			</button>
			<img :src="'data:image/jpg;base64,' + currentImage" class="image-size" />
		</div>
		<!-- <el-row>
			<el-col :span="4">
				<div v-if="currentImage" class="image-overlay">
					<button @click="closeImage" class="el-dialog__headerbtn">
						<i class="el-dialog__close el-icon el-icon-close"></i>
					</button>
					<img :src="'data:image/jpg;base64,' + currentImage" class="image-size" />
				</div>
			</el-col>
		</el-row> -->
	</div>
</template>

<!-- 表格元件-->
<script>
import Expand from '@/components/element/Expand2.vue'
import tableJs from '@/assets/js/tableTemplate.js'

export default {
	name: 'TableTemplate2',
	props: {
		//表頭設定
		columns: [],
		//table數據
		tableData: [],
		//總筆數
		total: null,
		//是否啟用載入動畫效果
		loading: null,
		//預設排序
		defaultsort: null,
		// 当前页
		currentPage: null,
		//每页多少条
		pageSize: null,
		//排序:ASC,DESC
		direction: null,
		//依據屬性欄位排序
		orderByName: null,
		//依據屬性欄位排序
		checkBoxShow: null,
		//按鈕顯示
		inDetailedButton: null,
		inEditButton: null,
		inDeleteButton: null,
		inAddButton: null,
	},
	components: {
		Expand,
	},
	data() {
		return {
			currentImage: '', // 当前显示的图片的 Base64 字符串
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			console.log('TableTemplate2 inAddButton=' + this.inAddButton)
			// console.log("pageSize=" + this.pageSize);
			// console.log("orderByName=" + this.orderByName);
			// console.log("direction=" + this.direction);
			// console.log("TableTemplate2 inDetailedButton=" + this.inDetailedButton);
			// console.log("TableTemplate2 inEditButton=" + this.inEditButton);
			// console.log("TableTemplate2 inDeleteButton=" + this.inDeleteButton);
		},
		showImage(base64Image) {
			this.currentImage = base64Image // 设置当前显示的图片 Base64 字符串
		},
		sortChange(column, prop, order) {
			console.log('點擊排序!')
			// 變更排序
			this.$emit('sort-change', column, prop, order)
			//在vue2.0中移除了组件的props的双向数据绑定功,故不可修改父組件傳送來的值
			let orderByName = ''
			let direction = ''
			if (column.order != null) {
				if ('ascending' == column.order) {
					direction = 'ASC'
				} else if ('descending' == column.order) {
					direction = 'DESC'
				}
			}
			if (column.prop != null) {
				orderByName = column.prop
			}
			this.$emit('getTableDataList', this.currentPage, this.pageSize, orderByName, direction)
		},
		rowClick(row, event, column) {
			console.log('單行點擊!')
			//单行点击
			tableJs.rowClick(row, event, column)
		},
		handleSizeChange(size) {
			console.log(`每页 ${size} 条`)
			//變更每頁數目
			this.$emit('handleSize-Change', size)
			this.$emit('getTableDataList', this.currentPage, size, this.orderByName, this.direction)
		},
		handleCurrentChange(currentPage) {
			console.log(`当前页: ${currentPage}`)
			//變更頁數
			this.$emit('handleCurrent-Change', currentPage)
			this.$emit('getTableDataList', currentPage, this.pageSize, this.orderByName, this.direction)
		},
		//設定表頭行的樣式
		tableHeaderColor() {
			return 'color:#fff;font-size:18px;text-align:center'
		},
		// eslint-disable-next-line no-unused-vars
		checkSelectable(row, index) {
			//禁用選取
			return tableJs.checkSelectable(row, index)
		},
		handleSelectChange(val) {
			//點擊單選
			tableJs.handleSelectChange(val)
		},
		// eslint-disable-next-line no-unused-vars
		getRowKeys(row) {
			return row.id
		},
		getSelection() {
			let arr = this.$refs.multipleTable.selection
			return arr
		},
		editClick(row) {
			console.log('row=' + row)
			this.$emit('editClick', row)
		},
		deleteClick(row) {
			console.log('row=' + row)
			this.$emit('deleteClick', row)
		},
		closeImage() {
			this.currentImage = null
		},
		detailedClick(row) {
			console.log('row=' + row)
			this.$emit('detailedClick', row)
		},
		addClick(row) {
			console.log('TableTemplate2.addClick row=' + row)
			this.$emit('addClick', row)
		},
	},
}
</script>

<style>
#myTable {
        margin: 15px;
    }

    .el-pagination__editor {
        border: 0px solid #d1dbe5 !important;
        padding: 0px !important;
    }

    .el-pager li.active {
        /**頁數底色**/
        background-color: #1E90FF;
        border-color: #1E90FF;
        color: #fff;
        cursor: default;
    }

    .el-table .ascending .sort-caret.ascending {
        /**排序ASC底色**/
        border-bottom-color: #1E90FF;
    }

    .el-table .descending .sort-caret.descending {
        /**排序DESC底色**/
        border-top-color: #1E90FF;
    }

    .el-table__header .el-table-column--selection .cell .el-checkbox:after {
        color: #333;
        content: "全選";
        font-size: 16px;
        margin-left: 12px;
        font-weight: bold;
    }

    /*.image-overlay {*/
    /*    position: fixed;*/
    /*    bottom: 80px; !* 调整位置 *!*/
    /*    left: 50%;*/
    /*    transform: translateX(-50%);*/
    /*    background-color: rgba(255, 255, 255, 0.9);*/
    /*    z-index: 9999;*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/

    .image-overlay {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        max-width: 100%;
        max-height: 100%;
    }

    .close-button {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 30px;
        background: transparent;
        border: none;
        color: darkred;
        cursor: pointer;
    }

    .image-size {
        width: 200px;
        height: 200px;
        max-width: 100%;
        max-height: 100%;
    }

		.el-dialog__headerbtn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
}
</style>
